












































































import UploadFile from '@/popupcomponents/roasterpanel/uploadFile/UploadFile';
export default UploadFile;
